export const boardList = [
  {
    valueProp: 'request',
    ratioProp: 'requestRatio',
    color: '#FF8A8A',
    label: '今日流量请求'
  },
  {
    valueProp: 'requestFilledRate',
    ratioProp: 'requestFilledRateRatio',
    color: '#FFA958',
    label: '流量填充率'
  },
  {
    valueProp: 'impressRate',
    ratioProp: 'impressRateRatio',
    color: '#FF82AC',
    label: '展示率'
  },
  {
    valueProp: 'impress',
    ratioProp: 'impressRatio',
    color: 'rgba(69,184,84,0.14)',
    label: '广告展示'
  },
  {
    valueProp: 'click',
    ratioProp: 'clickRatio',
    color: '#61A4E4',
    label: '广告点击'
  },
  {
    valueProp: 'advPrice',
    ratioProp: 'advPriceRatio',
    color: '#64C8BC',
    label: '广告收入'
  },
  {
    valueProp: 'income',
    ratioProp: 'incomeRatio',
    color: '#6554C0',
    label: '流量成本'
  }
]
